<template lang="pug">
modal(size='xl' @close='$emit("close")')
  generic-form#FormAddOrEditExternalProjectItem(:endpoint='endpoint' :allowShortcuts='true' @submitSuccess='onSubmitSuccess')
    template(#default='{ config }')
      external-project-item-form(v-if='config' :projectId='projectId' :isModeEdit='isModeEdit')
        template(#header) {{ $t(isModeEdit ? 'Edit external product' : 'Add external product') }}
        template(#actions)
          form-action(type='secondary' :primary='true' name='AddOrUpdateProductListItem' formActionName='AddOrEditExternalProjectListItemForm/AddOrUpdateProductListItem')
            template(#inner) {{ $t(isModeEdit ? 'Save' : 'Create') }}
    template(#error)
      error-state-modal(@close='$emit("close")')
</template>

<script lang="ts">
import { type IProjectProductItem } from '../types'
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import { useInquiriesStore } from '@/pages/inquiries/store'
import { createUrlWithQueryParams, parsePrice } from '@/utilities'
import { computed, defineComponent } from 'vue'

const FormAddOrEditExternalProjectItem = defineComponent({
  inheritAttrs: false,
  props: {
    projectId: { type: String, required: true },
    productId: String,
    inquiryId: String,
    parentProductId: String,
    tag: String,
    sourceProjectId: String,
  },
  setup(props, { emit }) {
    const endpoint = computed(() =>
      createUrlWithQueryParams('/_/AddOrEditExternalProjectItem', {
        productId: props.productId || '*',
        parentProductId: props.parentProductId,
        projectId: props.projectId,
        tag: props.tag,
      }),
    )
    const store = useInquiriesStore()
    return {
      parsePrice,
      endpoint,
      onSubmitSuccess: async (r: IFormResponseInfo<IProjectProductItem>) => {
        if (props.inquiryId) {
          await store.addProductsToInquiry(props.projectId, props.inquiryId, props.sourceProjectId, [r.data.productId])
        }
        emit('close')
      },
      isModeEdit: computed(() => !!props.productId),
    }
  },
})
export default FormAddOrEditExternalProjectItem
</script>
