<template lang="pug">
div(class='absolute right-0 top-4 z-50')
  btn(plain icon='x' data-target='form-header-close' @click='$emit("close")')
empty-state(:title='$t("Unexpected error")' :message='$t("There has been an error")' centered type='danger') 
  template(#buttons)
    btn(@click='$emit("close")') {{ $t('go back') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const ErrorStateModal = defineComponent({})
export default ErrorStateModal
</script>
